import { useBreakpoints } from "@gx-ui";

interface OwnProps {
	mediaQuery: string;
	mp4: string;
	poster: string;
	webm?: string;
}

type Props = OwnProps;

const useVideoArtDirection = (sources: Array<Props>): Props => {
	const { getFirstMatch } = useBreakpoints();

	return getFirstMatch(
		sources.reduce((o, i) => ({ ...o, [i.mediaQuery]: i }), {}),
		sources[0]
	);
};

export default useVideoArtDirection;
