import React, { FunctionComponent } from "react";
import { Global } from "@emotion/react";
import styles from "../styles";

interface OwnProps {}

type Props = OwnProps;

const StylesProvider: FunctionComponent<Props> = ({ children }) => {
	return (
		<>
			<Global styles={styles} />
			{children}
		</>
	);
};

export default StylesProvider;
