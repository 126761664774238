import React, { FC, useContext, useMemo } from "react";
import styled from "@emotion/styled";
import { MediaContext } from "@gx";
import { graphql, useStaticQuery, withPrefix } from "gatsby";

const MuteButton = styled.button`
	border: none;
	background: none;
	display: block;
	padding: 0 15px;
	margin: 0;
	cursor: pointer;

	img {
		display: block;
		height: 20px;
	}
`;

interface OwnProps {}

type Props = OwnProps;

const Mute: FC<Props> = (props) => {
	const { muted, mute, unmute } = useContext(MediaContext);

	const data = useStaticQuery(graphql`
		query MuteQuery {
			allFile(filter: { name: { in: ["mute", "unmute"] } }) {
				nodes {
					name
					childImageSharp {
						resize(width: 40) {
							src
						}
					}
				}
			}
		}
	`);

	const srcMute = useMemo(
		() =>
			withPrefix(
				data.allFile.nodes.find((n: any) => n.name == "mute").childImageSharp
					.resize.src
			),
		[data]
	);
	const srcUnmute = useMemo(
		() =>
			withPrefix(
				data.allFile.nodes.find((n: any) => n.name == "unmute").childImageSharp
					.resize.src
			),
		[data]
	);

	return (
		<MuteButton onClick={muted ? unmute : mute} {...props}>
			<img src={muted ? srcMute : srcUnmute} alt="mute" />
		</MuteButton>
	);
};

export default Mute;
