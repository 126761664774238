import React, { forwardRef, ForwardRefRenderFunction, HTMLProps } from "react";

const ForwardVideo = (props: any) => {
	const forwardVideoRenderFunction: ForwardRefRenderFunction<
		HTMLVideoElement,
		HTMLProps<HTMLVideoElement>
	> = (videoProps, ref) => <video ref={ref} {...videoProps} {...props} />;

	return forwardRef(forwardVideoRenderFunction);
};

export default ForwardVideo;
