import React, { FunctionComponent, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import { graphql, navigate, useStaticQuery, withPrefix } from "gatsby";
import { usePreloads } from "@gx";

const AboutWrapper = styled.div`
	opacity: 0;
	overflow-y: scroll;

	position: fixed;
	top: 0;
	left: 0;
	padding-top: 140px;
	padding-bottom: 140px;
	width: 100%;
	height: 100%;
	z-index: 7777;

	cursor: pointer;
	color: white;
	background-size: cover;
	background-position: center;

	transition: opacity ${(props) => props.theme.animations.about}ms ease-in;
	visibility: hidden;

	&.enter,
	&.enter-done,
	&.exit {
		visibility: visible;
	}

	&.enter-active,
	&.enter-done,
	&.exit {
		opacity: 1;
	}

	&.exit-active {
		opacity: 0;
	}
`;

const AboutText = styled.p`
	margin: 0 auto;
	padding: 0 20px;
	max-width: 1080px;

	text-align: justify;
	line-height: 1.8em;
	font-size: 125%;
`;

const AboutParagraph = styled(AboutText)`
	&:not(:last-of-type):after {
		content: "--";
		display: block;
	}
`;

const AboutTitle = styled(AboutText.withComponent("h3"))`
	text-align: left;
	font-weight: bold;
	margin-bottom: 0;
`;

const AboutClose = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 20px;
	width: 80px;
	height: 80px;
	border: none;
	background: none;
	color: white;
	cursor: pointer;

	&:before {
		content: "+";
		font-family: sans-serif;
		display: block;
		font-size: 40px;
		line-height: 1em;
		transform: rotateZ(45deg);
	}
`;

const About: FunctionComponent<CSSTransitionProps> = (props) => {
	const data = useStaticQuery(graphql`
		query AboutQuery {
			allFile(filter: { relativePath: { regex: "/.*about.*\\.png/" } }) {
				nodes {
					childImageSharp {
						resize(width: 1920) {
							src
						}
					}
				}
			}
		}
	`);

	const backgrounds = useMemo(
		() =>
			data.allFile.nodes.map((n: any) =>
				withPrefix(n.childImageSharp.resize.src)
			),
		[data]
	);

	const [background, setBackground] = useState(backgrounds[0]);

	usePreloads([
		{
			href: background,
			as: "image",
		},
		...backgrounds.map((src: string) => ({
			rel: "prefetch",
			href: src,
			as: "image",
		})),
	]);

	return (
		<CSSTransition
			onExited={() => {
				const src = backgrounds[Math.floor(Math.random() * backgrounds.length)];
				fetch(src).then(() => setBackground(src));
			}}
			{...props}
		>
			<AboutWrapper
				style={{
					backgroundImage: `linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2)), url(${background})`,
				}}
				onClick={() => navigate("/")}
			>
				<AboutClose onClick={() => navigate("/")} />
				<AboutTitle>GB2022-CLUB - VALORISE THE CARE</AboutTitle>
				<AboutParagraph>
					THIS SEASON, SAMPLE-CM JUMPS ON <b>RIDING SPORTS</b> FOR A NEW
					COLLECTION OF GRAND BASSIN <b>SHAMELESSLY TENDER</b>. THE{" "}
					<b>ECOFEMINIST</b> GB2022-CLUB STANDS FOR A GENDER NEUTRAL{" "}
					<b>SENSIBILITY</b>.
				</AboutParagraph>
				<AboutParagraph>
					AS THE ONLY MIXED SPORTS OF THE OLYMPICS AND WITH AN OVERWHELMING
					MAJORITY OF WOMEN MEMBERS, THE EQUESTRIAN COMPETITIONS ARE YET MOSTLY
					WON BY WHITE CIS-MALE ATHLETES. THE CARING AND EMOTIONAL RELATIONSHIPS
					WOMEN RIDERS WOULD HAVE WITH THEIR HORSES ARE OFTEN POINTED THROUGH
					GENDER PREJUDICES WHEN PRESENTED AS “ OVERPROTECTIVE” AND AT THE
					EXPENSE OF THE PERFORMANCE. MOCKED AND STIGMATISED, THE SO CALLED
					“FEMININE” WAYS TO RIDE, ARE ALSO JOINED BY EXTREMELY GENDERED GEAR
					MARKETING (PINK OUTFIT, GLITTERY BRUSHES AND GROOMING KIT…)
					UNDERLINING ESSENTIALISM, REINFORCING THE GENDER EXPECTATIONS WHILE
					MASKING THE SYSTEMIC INEQUITY AT PROFESSIONAL LEVELS.
				</AboutParagraph>
				<AboutParagraph>
					WHERE A GENERAL REAPPRECIATION OF THE CARE AND THE PARTNERSHIP WITH
					THE HORSE WOULD LEAD TO DEEPER COMMUNAL KNOWLEDGE AND BETTER ANIMAL
					WELFARE, WE TRULY BELIEVE IT COULD ALSO CARRY SIGNIFICANT AND
					INSPIRING SPORT PERFORMANCES. THE NEW COLLECTION OF GRAND BASSIN
					WISHES TO COMBINE VULNERABILITY WITH POWER. THESE REVALUATION
					STRATEGIES ARE PARTICULARLY RELEVANT ALTERNATIVES TO MASCULINIZATION
					AS THE ONLY PROVIDENTIAL WAY TOWARDS EMANCIPATION, EQUALITY AND
					SUCCESS. IN CLEAR CONNECTION WITH THE NATURAL WORLD, THE GB2022-CLUB
					INTERWEAVES FIGURES OF CONTEMPORARY AMAZONS WITH UP-TO-DATE WITCHES.
					THESE NEW MODELS OFFER DIFFERENT PATHS TO EXAMINE GENDER FLUIDITY IN
					SPORTS WHILE THEY WORK ALSO AS OPPORTUNITIES TO RE-DEFINE SPORT VALUES
					AND RE-DESIGN SPORTSWEAR.
				</AboutParagraph>
				<AboutParagraph>
					THE GB2022-CLUB ARTICULATES <b>SETS OF ELEMENTS</b> - COMBINED
					TOGETHER THROUGH <b>BRAIDING TECHNIQUES</b>. THEY PAY TRIBUTE TO THIS
					SPECIAL TIME OF GROOMING SPENT WITH THE HORSE BEFORE RIDING. TINY
					GENTLE GESTURES AND CARING DAILY RITUALS ARE HERE AT THE CENTER OF THE
					CREATIVE PROCESS. ATTACHED OR DETACHED, BRAIDED OR NOT, THE PIECES
					OSCILLATE BETWEEN RODEO FRINGES AND TIGHT EQUESTRIAN GEAR, AMAZON ONE
					BREAST TOP AND JOCKEY JACKET, PONY MOTTLED COAT AND STRETCHY VELVET.
					EACH DESIGN IS NAMED FROM A TYPICAL <b>HORSE NAME</b> INVITING YOU TO
					CARE FOR AND <b>INTERACT</b> WITH.
				</AboutParagraph>
				<AboutParagraph>
					THE DESIGNS ARE MADE LOCALLY ON ORDER IN BERLIN ATELIER SINCE 2015
					WITH LUXURY STANDARDS. THE ANNUAL GRAND BASSIN COLLECTIONS ARE JOINED
					BY ONGOING AND TROUBLEMAKING CONVERSATIONS. STANDING FOR A SLOW
					FASHION, SAMPLE-CM TAKES THE TIME AND EXPANDS THE FASHION MOMENT TO
					LONG LASTING AND INTERACTIVE CLOTHING REINVENTING THEMSELVES WITH THE
					USER.
				</AboutParagraph>
				<AboutTitle>
					#GB2022CLUB IS A PROJECT OF GRAND BASSIN BY SAMPLE-CM BERLIN.
				</AboutTitle>
				<AboutParagraph>
					--
					<br />
					creative direction & fashion. margot charbonnier - video. anders bigum
					- model. patricia reina supermodelmgmt - styling. emmanuel pierre -
					muah. lucia binta - assistant video. alejandro munoz - sound. adam
					tudoret & sebastien michel - web. guilhem barboux. thanks. Lea
					charbonnier - special thanks. horse farm waldkante. sample-cm.com
				</AboutParagraph>
			</AboutWrapper>
		</CSSTransition>
	);
};

export default About;
