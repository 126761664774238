import React, { FunctionComponent } from "react";
import Helmet from "react-helmet";

const PreloadLink: FunctionComponent<
	React.LinkHTMLAttributes<HTMLLinkElement>
> = (props) => (
	<Helmet>
		<link rel="preload" {...props} />
	</Helmet>
);

export default PreloadLink;
