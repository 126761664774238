import React, { FunctionComponent, useContext } from "react";
import { LoadingContext, withLoading, withMedia, withPreloads } from "@gx";
import { useTheme } from "@emotion/react";
import { PageProps, withPrefix } from "gatsby";
import Loading from "@src/components/Loading";
import About from "@src/elements/About";
import Logo from "@src/elements/Logo";

interface OwnProps {}

type Props = OwnProps & PageProps;

const WrapPage: FunctionComponent<Props> = ({
	children,
	location,
}): JSX.Element => {
	const theme = useTheme();
	const { stopLoading } = useContext(LoadingContext);

	return (
		<>
			<Loading onExit={() => stopLoading()} />
			<Logo />
			{children}
			<About
				appear={true}
				in={location.pathname == withPrefix("/about/")}
				timeout={theme.animations.about}
			/>
		</>
	);
};

export default withPreloads(withMedia(withLoading(WrapPage)));
