import "@emotion/react";

declare module "@emotion/react" {
	export interface Theme extends Record<string, any> {}
}

const breakpoints = [768, 1920, 2560];
// [0] 720p : <768px
// [1] 1080p : >768px
// [2] 1440p : >1920px
// [3] 2160 : >2560px

export default {
	breakpoints: [
		`(max-width : ${breakpoints[0]}px)`,
		...breakpoints.map((b) => `(min-width : ${b}px)`),
	],
	fonts: {
		critical: "century-critical",
		body: "century",
		heading: "franklin_gothic_heavyitalic",
	},
	fontSizes: {
		desktop: "125%",
		mobile: "100%",
	},
	zIndices: {
		overlay: 9999,
		loading: 8888,
		mute: 7777,
		logo: 7777,
		menu: 6666,
	},
	animations: {
		about: 300,
		page: {
			enter: 2200,
			exit: 1200,
		},
		loading: {
			enter: 400,
			exit: 600,
			waiting: 1400,
		},
	},
};
